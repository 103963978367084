import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';

// Components
import Layout from '../../components/layout/Layout';
import ArticlesFilter from '../../components/filter/Articles';
import ArticleCard from '../../components/card/Article';
import PaginationBlock from '../../components/block/Pagination';

// Articles Tag
const ArticlesTagPage = ({ pageContext, data }) => {
  
  const {t} = useTranslation();

  const { tag, languagePath } = pageContext;
  const { totalCount } = data.articles;

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.articles'),
          item: languagePath + "articles/"
        },
        { 
          name: t('breadcrumb.tags'),
          item: languagePath + "articles/tags/"
        },
        { 
          name: t('article.tags.' + tag + '.label')
        },
      ]
    }
  ];

  return (
    <Layout 
      pageType="classic" 
      pageTitle={t('article.tags.' + tag + '.meta.title', { title: data.site.siteMetadata.title, total_count:totalCount })} 
      pageDescription={t('article.tags.' + tag + '.meta.description', { title: data.site.siteMetadata.title, total_count:totalCount })} 
      pageKeywords={t('article.tags.' + tag + '.meta.keywords', { title: data.site.siteMetadata.title, total_count:totalCount })} 
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-1 content-space-b-md-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/articles/`}>{t('breadcrumb.articles')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/articles/tags/`}>{t('breadcrumb.tags')}</Link></li>
            <li className="breadcrumb-item active">{t('article.tags.' + tag + '.label')}</li>
          </ol>
        </nav>
        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
          <h1 className="display-5">{t('article.tags.' + tag + '.title', { title: data.site.siteMetadata.title, total_count: totalCount })}</h1>
          <p className="lead">{t('article.tags.' + tag + '.subtitle', { title: data.site.siteMetadata.title, total_count: totalCount })}</p>
        </div>
      </div>
      <div className="container content-space-b-2 content-space-b-lg-3">
        <div className="row col-lg-divider">
          <div className="col-lg-3">
            <Link className="card-link ms-2" to="/articles/">
              <div className="card card-info-link card-sm d-grid mb-5 mb-md-3">
                <div className="card-body">
                  <span className="bi-chevron-left small ms-1"></span> {t('back')}
                </div>
              </div>
            </Link>
            <ArticlesFilter data={data} />
          </div>
          <div className="col-lg-9">
            <div className="ps-lg-2">
              <div className="row gx-3 mb-7">
                {data.articles.nodes &&
                  data.articles.nodes.map(article => (
                    <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-sm-6 col-lg-6 mb-4"/>
                  ))
                }
              </div>
            </div>
            <div>
              <PaginationBlock 
                path={`articles/tags/${slugify(tag)}`} 
                currentPage={pageContext.currentPage} 
                numPages={pageContext.numPages} 
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

ArticlesTagPage.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    tags: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default ArticlesTagPage

export const query = graphql`
  query ($tag: String, $language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    articles: allMdx(
      filter: { 
        fileAbsolutePath: { regex: "/data/articles/" },
        frontmatter: { tags: { in: [$tag] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_published, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          description
          author
          publisher
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date_published(formatString: "D MMMM YYYY", locale: $language)
          date_modified(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    categories: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___category) {
        title: fieldValue
        totalCount
      }
    }
    tags: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___tags) {
        title: fieldValue
        totalCount
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "articles", "categories", "tags", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`